/* PWA Prompt Styles */
.pwa-prompt-overlay {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	display: flex;
	justify-content: center;
	padding: 0 16px 16px;
	pointer-events: none;
}

.pwa-prompt-container {
	background-color: rgba(250, 250, 250, 0.95);
	border-radius: 14px;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
	width: 100%;
	max-width: 500px;
	overflow: hidden;
	animation: slideUp 0.3s ease-out forwards;
	position: relative;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	pointer-events: auto;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

@keyframes slideUp {
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.pwa-prompt-header {
	display: flex;
	align-items: center;
	padding: 16px 16px 8px;
}

.pwa-prompt-icon {
	margin-right: 12px;
}

.app-icon {
	width: 60px;
	height: 60px;
	border-radius: 12px;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	position: relative;
	overflow: hidden;
}

.app-logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.pwa-prompt-title {
	flex: 1;
}

.pwa-prompt-title h3 {
	margin: 0;
	font-size: 18px;
	font-weight: 600;
	color: #000;
}

.pwa-prompt-title p {
	margin: 0;
	font-size: 14px;
	color: #666;
}

.pwa-prompt-close {
	background: none;
	border: none;
	font-size: 24px;
	color: #999;
	cursor: pointer;
	padding: 0;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.pwa-prompt-close:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.pwa-prompt-content {
	padding: 0 16px 16px;
}

.pwa-prompt-content p {
	margin: 0;
	font-size: 14px;
	line-height: 1.4;
	color: #333;
}

/* Debug info styling */
.debug-info {
	margin-top: 8px !important;
	padding: 4px 8px;
	background-color: #f0f0f0;
	border-radius: 4px;
	font-size: 12px !important;
	font-family: monospace;
	color: #666 !important;
	border-left: 3px solid #ffb06c;
}

/* Debug reset button */
.debug-reset-button {
	margin-top: 8px;
	padding: 4px 8px;
	background-color: #f0f0f0;
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 12px;
	color: #666;
	cursor: pointer;
	transition: background-color 0.2s;
}

.debug-reset-button:hover {
	background-color: #e5e5e5;
}

/* Floating debug reset button */
.pwa-debug-reset {
	position: fixed;
	bottom: 20px;
	right: 20px;
	padding: 8px 12px;
	background-color: rgba(255, 176, 108, 0.9);
	color: white;
	border: none;
	border-radius: 8px;
	font-size: 14px;
	cursor: pointer;
	z-index: 9998;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	transition: background-color 0.2s, transform 0.1s;
}

.pwa-debug-reset:hover {
	background-color: rgba(244, 158, 83, 0.9);
}

.pwa-debug-reset:active {
	transform: scale(0.95);
}

.pwa-prompt-actions {
	display: flex;
	padding: 8px 16px 16px;
	gap: 12px;
}

.pwa-prompt-cancel,
.pwa-prompt-install {
	flex: 1;
	padding: 12px;
	border-radius: 12px;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s, transform 0.1s;
	border: none;
}

.pwa-prompt-cancel {
	background-color: #f1f1f1;
	color: #333;
}

.pwa-prompt-install {
	background: var(--primary-gradient, linear-gradient(to right, #ffb06c, #f49e53));
	color: white;
}

.pwa-prompt-cancel:hover {
	background-color: #e5e5e5;
}

.pwa-prompt-install:hover {
	opacity: 0.9;
}

.pwa-prompt-cancel:active,
.pwa-prompt-install:active {
	transform: scale(0.98);
}

.pwa-prompt-arrow {
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 20px;
	height: 20px;
	background-color: rgba(250, 250, 250, 0.95);
	transform: translateX(-50%) rotate(45deg);
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Mobile optimizations */
@media (max-width: 480px) {
	.pwa-prompt-container {
		border-radius: 14px 14px 0 0;
		max-width: 100%;
	}

	.pwa-prompt-arrow {
		display: none;
	}

	.pwa-prompt-overlay {
		padding: 0;
	}
}
