.friendships-section {
	padding: 0;
	width: 100%;
}

.stats-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	gap: 1.5rem;
	margin-bottom: 2rem;
}

.charts-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
	gap: 2rem;
	margin-top: 2rem;
}

.chart-container {
	background: white;
	padding: 2rem;
	border-radius: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.03);
	transition: all 0.3s ease;
}

.chart-container:hover {
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.04);
	transform: translateY(-2px);
}

.chart-container h3 {
	color: #1e293b;
	font-size: 0.875rem;
	font-weight: 600;
	margin-bottom: 2rem;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.categories-list {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.category-item {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	padding: 1rem;
	border-radius: 12px;
	background: #f8fafc;
	transition: all 0.2s ease;
}

.category-item:hover {
	background: #f1f5f9;
}

.category-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.category-name {
	font-weight: 500;
	color: #1e293b;
	font-size: 0.875rem;
}

.category-count {
	color: #1e293b;
	font-weight: 500;
	font-size: 0.875rem;
}

.percentage {
	color: #64748b;
	font-size: 0.75rem;
	margin-left: 0.5rem;
	font-weight: 400;
}

.category-bar-container {
	width: 100%;
	height: 6px;
	background: #e2e8f0;
	border-radius: 3px;
	overflow: hidden;
}

.category-bar {
	height: 100%;
	border-radius: 3px;
	transition: all 0.3s ease;
}

.specific-issues {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 0.75rem;
	margin-top: 0.5rem;
}

.issue-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.75rem 1rem;
	background: white;
	border-radius: 8px;
	font-size: 0.75rem;
	color: #64748b;
	transition: all 0.2s ease;
}

.issue-item:hover {
	background: #fff3e6;
	color: #f49e53;
}

.issue-count {
	font-weight: 500;
	color: #1e293b;
}

/* Loading State */
.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

.loading-text {
	color: #64748b;
	font-size: 0.875rem;
	font-weight: 500;
}

/* Error State */
.error-message {
	text-align: center;
	color: #ef4444;
	padding: 1.5rem;
	background: #fef2f2;
	border-radius: 12px;
	margin: 1rem;
	border: 1px solid #fee2e2;
}

/* Custom Tooltip */
.custom-tooltip {
	background: white;
	border-radius: 8px;
	padding: 1rem;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.03);
	border: none;
}

.tooltip-label {
	color: #64748b;
	font-size: 0.75rem;
	margin: 0 0 0.5rem 0;
	font-weight: 500;
}

.tooltip-value {
	color: #1e293b;
	font-size: 0.875rem;
	font-weight: 600;
	margin: 0;
}

/* Chart Customization */
.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
	stroke: #e2e8f0;
	stroke-opacity: 0.5;
}

.recharts-yAxis .recharts-text,
.recharts-xAxis .recharts-text {
	fill: #64748b;
	font-size: 0.75rem;
	font-weight: 500;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
	.friendships-section {
		padding: 1rem;
	}

	.charts-grid {
		grid-template-columns: 1fr;
	}

	.chart-container {
		padding: 1.5rem;
	}

	.chart-container h3 {
		font-size: 0.75rem;
		margin-bottom: 1.5rem;
	}

	.specific-issues {
		grid-template-columns: 1fr;
	}
}
