.contact-page {
	background: #fffdf8;
	position: relative;
	min-height: 100vh;
}

.contact-page::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/Background.png');
	background-size: contain;
	background-position: center;
	background-repeat: repeat;
	opacity: 0.4;
	z-index: 0;
}
.contact__headline {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 1;
	gap: 24px;
	max-width: 1280px;
	margin: 60px auto 120px;
}

.contact__headline h2 {
	color: #140c01;
	font-weight: bold;
	line-height: 1.1;
	font-size: 80px;
	margin: 0px;
	max-width: 700px;
}
.contact__headline p {
	color: 737373;
	font-size: 14px;
	font-size: 'Open Sans';
	font-weight: normal;
	margin: 0px;
	max-width: 440px;
}
.contact__container {
	max-width: 1280px;
	height: 666px;
	margin: 0px auto;
	display: flex;
	gap: 16px;
	z-index: 1;
	margin-bottom: 120px;
}
.contact__box {
	height: 100%;
	background: #fff;
	z-index: 1;
	border: 1px solid #e5e5e5;
	border-radius: 16px;
	width: 100%;
	padding: 32px;
	gap: 48px;
	display: flex;
	flex-direction: column;
}
.contact__box-headline h3 {
	font-weight: bold;
	margin: 0px;
	font-size: 32px;
	line-height: 1.1;
	color: #140c01;
}
.contact__box-headline p {
	font-size: 14px;
	font-weight: normal;
	font-family: 'Open Sans';
	color: #737373;
	max-width: 248px;
}
.contact__box form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 32px;
}
.contact__form-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.input-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.input-container label {
	font-weight: 500;
	line-height: 1.1;
	font-size: 14px;
	font-family: 'Open Sans';
	color: #140c01;
}
.input-container input,
.input-container textarea {
	border: 1px solid #e5e5e5;
	padding: 16px 24px;
	width: 100%;
	font-family: 'Open Sans';
	font-weight: normal;
	font-size: 14px;
}
.input-container input {
	border-radius: 999px;
}
.input-container textarea {
	min-height: 150px;
	border-radius: 16px;
	resize: none;
	max-height: 150px;
}
.contact__button {
	width: 100%;
	background: var(--primary-gradient);
	color: #fff;
	border: none;
	border-radius: 40px;
	padding: 16px 32px;
	cursor: pointer;
}
.contact__info {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
}

.info-box {
	background: #fff;
	z-index: 1;
	border-radius: 16px;
	border: 1px solid #e5e5e5;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 48px;
	padding: 32px 24px;
}
.info-box-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 4px;
}
.info-box-text h5 {
	margin: 0px;
	color: #140c01;
	font-weight: 500;
	line-height: 1.1;
	font-size: 18px;
}
.info-box-text p {
	margin: 0px;
	color: #737373;
	font-size: 14px;
	font-weight: normal;
	font-family: 'Open Sans';
}
.info-box-top {
	font-weight: 400;
	line-height: 1.1;
	font-size: 16px;
	margin: 0px;
	color: #140c01;
	opacity: 0.4;
}
.contact-icon {
	width: 80px;
	height: 80px;
	margin: 0px;
	border-radius: 50%;
	padding: 16px;
	background: #f5f4f1;
	border: 1px solid #e5e5e5;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon {
	width: 48px;
	height: 48px;
	color: #000 !important;
}
.info-box-social {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
}
.social-box {
	background: #fff;
	width: 100%;
	height: 100%;
	z-index: 1;
	border-radius: 16px;
	border: 1px solid #e5e5e5;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 48px;
	padding: 24px 24px;
	cursor: pointer;
}
.social-box:hover .icon {
	color: #565656 !important;
}

@media (max-width: 1268px) {
	.contact__headline {
		align-items: center;
		text-align: center;
		margin: 40px auto 80px;
	}

	.contact__headline h2 {
		font-size: 48px;
	}

	.contact__headline p {
		font-size: 12px;
	}

	.contact__container {
		flex-direction: column;
		gap: 32px;
		margin-bottom: 80px;
	}
	.contact__container {
		height: auto;
		padding: 0px 20px;
	}
	.contact__box,
	.contact__info {
		width: 100%;
	}
}
@media (max-width: 580px) {
	.contact__info {
		grid-template-columns: 1fr;
	}
}
.disabled {
	opacity: 0.3;
	cursor: not-allowed;
}
