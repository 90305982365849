.edit-user-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.edit-user-modal {
	background: white;
	border-radius: 16px;
	width: 90%;
	max-width: 600px;
	max-height: 90vh;
	overflow-y: auto;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
	animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.edit-user-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem;
	border-bottom: 1px solid #f1f5f9;
}

.edit-user-modal-header h2 {
	margin: 0;
	font-size: 1.25rem;
	font-weight: 600;
	color: #1e293b;
}

.close-button {
	background: transparent;
	border: none;
	cursor: pointer;
	font-size: 1.25rem;
	color: #64748b;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	border-radius: 50%;
	transition: all 0.2s;
}

.close-button:hover {
	background: #f1f5f9;
	color: #1e293b;
}

.edit-user-form {
	padding: 1.5rem;
}

.form-group {
	margin-bottom: 1.25rem;
}

.form-group label {
	display: block;
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #64748b;
}

.form-group input,
.form-group textarea,
.form-group select {
	width: 100%;
	padding: 0.75rem 1rem;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	font-size: 0.875rem;
	background: white;
	transition: all 0.2s;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
	outline: none;
	border-color: #f49e53;
	box-shadow: 0 0 0 3px rgba(244, 158, 83, 0.1);
}

.form-group input.error,
.form-group textarea.error,
.form-group select.error {
	border-color: #ef4444;
}

.error-message {
	color: #ef4444;
	font-size: 0.75rem;
	margin-top: 0.25rem;
	display: block;
}

.form-row {
	display: flex;
	gap: 1rem;
}

.form-row .form-group {
	flex: 1;
}

.checkbox-row {
	margin-bottom: 1.5rem;
}

.checkbox-group {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 0;
}

.checkbox-group input {
	width: auto;
	margin: 0;
}

.checkbox-group label {
	margin-bottom: 0;
	cursor: pointer;
}

.form-actions {
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	margin-top: 1.5rem;
}

.cancel-button,
.save-button {
	padding: 0.75rem 1.5rem;
	border-radius: 8px;
	font-size: 0.875rem;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.2s;
}

.cancel-button {
	background: #f1f5f9;
	color: #64748b;
	border: none;
}

.cancel-button:hover {
	background: #e2e8f0;
	color: #1e293b;
}

.save-button {
	background: #f49e53;
	color: white;
	border: none;
}

.save-button:hover {
	background: #f4c053;
	transform: translateY(-1px);
}

.save-button:active {
	transform: translateY(0);
}

/* Responsive adjustments */
@media (max-width: 640px) {
	.form-row {
		flex-direction: column;
		gap: 0;
	}

	.edit-user-modal {
		width: 95%;
	}
}
