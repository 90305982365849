.users-section {
	padding: 0;
	width: 100%;
}

.users-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
	gap: 2rem;
}

.users-title {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.users-icon {
	font-size: 1.25rem;
	color: #f49e53;
}

.users-title h2 {
	font-size: 0.875rem;
	font-weight: 600;
	color: #64748b;
	margin: 0;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.users-count {
	background: #fff3e6;
	color: #f49e53;
	padding: 0.25rem 0.75rem;
	border-radius: 6px;
	font-size: 0.75rem;
	font-weight: 500;
}

.search-bar {
	position: relative;
	flex: 1;
	max-width: 300px;
}

.search-bar input {
	width: 100%;
	padding: 0.75rem 1rem 0.75rem 2.5rem;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	font-size: 0.875rem;
	background: white;
	transition: all 0.2s;
}

.search-bar input:focus {
	outline: none;
	border-color: #f49e53;
	box-shadow: 0 0 0 3px rgba(244, 158, 83, 0.1);
}

.search-icon {
	position: absolute;
	left: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	color: #94a3b8;
	font-size: 1rem;
}

.users-table-container {
	background: white;
	border-radius: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.03);
	overflow: hidden;
	transition: all 0.3s ease;
}

.users-table {
	width: 100%;
	border-collapse: collapse;
}

.users-table th {
	background: white;
	padding: 1.25rem 1.5rem;
	text-align: left;
	font-weight: 500;
	color: #64748b;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	border-bottom: 1px solid #f1f5f9;
	position: relative;
}

.user-row {
	transition: all 0.2s ease;
}

.user-row:hover {
	background-color: #f8fafc;
}

.user-row td {
	padding: 1.25rem 1.5rem;
	border-bottom: 1px solid #f1f5f9;
}

.user-info {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.user-avatar {
	width: 36px;
	height: 36px;
	border-radius: 8px;
	object-fit: cover;
}

.user-details {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.user-name {
	font-weight: 500;
	color: #1e293b;
	margin: 0;
	font-size: 0.875rem;
}

.user-email {
	font-size: 0.75rem;
	color: #64748b;
	margin: 0;
}

.friends-count {
	background: #fff3e6;
	color: #f49e53;
	padding: 0.375rem 0.75rem;
	border-radius: 6px;
	font-size: 0.75rem;
	font-weight: 500;
	display: inline-block;
	min-width: 2.5rem;
	text-align: center;
}

.action-buttons {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.user-row:hover .action-buttons {
	opacity: 1;
}

.delete-button,
.blacklist-button,
.edit-button {
	padding: 0.5rem;
	border: none;
	background: transparent;
	cursor: pointer;
	border-radius: 6px;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #94a3b8;
}

.delete-button:hover {
	background: #fef2f2;
	color: #ef4444;
}

.blacklist-button:hover {
	background: #f1f5f9;
	color: #1e293b;
}

.edit-button:hover {
	background: #fff3e6;
	color: #f49e53;
}

.error-message {
	text-align: center;
	padding: 2rem;
	background: white;
	border-radius: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.03);
	color: #ef4444;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.retry-button {
	padding: 0.75rem 1.5rem;
	background: #f49e53;
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 500;
	transition: all 0.2s ease;
}

.retry-button:hover {
	background: #f4c053;
	transform: translateY(-1px);
}

.retry-button:active {
	transform: translateY(0);
}

/* Loading State */
.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

.loading-text {
	color: #64748b;
	font-size: 0.875rem;
	font-weight: 500;
}

/* Sorting */
.sortable {
	cursor: pointer;
	user-select: none;
	position: relative;
	padding-right: 2rem !important;
}

.sortable:hover {
	color: #1e293b;
}

.sort-icon {
	position: absolute;
	right: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1rem;
	transition: all 0.2s;
	color: #cbd5e1;
}

.sort-icon.inactive {
	opacity: 0.3;
}

.sort-icon.active {
	color: #f49e53;
	opacity: 1;
}

/* Admin and verification badges */
.admin-badge,
.unverified-badge {
	font-size: 0.625rem;
	padding: 0.125rem 0.375rem;
	border-radius: 4px;
	margin-left: 0.5rem;
	font-weight: 500;
	display: inline-block;
	vertical-align: middle;
}

.admin-badge {
	background: #f0fdf4;
	color: #16a34a;
	border: 1px solid #dcfce7;
}

.unverified-badge {
	background: #fff7ed;
	color: #ea580c;
	border: 1px solid #ffedd5;
}
