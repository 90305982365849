:root {
	--primary: #f49e53;
	--primary-light: #fff3e6;
	--danger: #dc2626;
	--danger-light: #fee2e2;
	--surface: #ffffff;
	--surface-hover: #f8fafc;
	--border: #e2e8f0;
	--text-primary: #1e293b;
	--text-secondary: #64748b;
	--text-tertiary: #94a3b8;
}

.blacklist-section {
	padding: 2rem;
	background: var(--surface);
	min-height: 100vh;
}

.blacklist-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2.5rem;
	padding: 0 0.5rem;
}

.title-section {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.blacklist-icon {
	font-size: 1.25rem;
	color: var(--danger);
}

.title-section h2 {
	font-size: 1.125rem;
	font-weight: 600;
	color: var(--text-primary);
	margin: 0;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.blacklist-count {
	background: var(--danger-light);
	color: var(--danger);
	padding: 0.25rem 0.75rem;
	border-radius: 6px;
	font-size: 0.75rem;
	font-weight: 500;
}

.search-container {
	position: relative;
	width: 300px;
}

.search-icon {
	position: absolute;
	left: 1rem;
	top: 50%;
	transform: translateY(-50%);
	color: var(--text-tertiary);
	font-size: 0.875rem;
}

.search-input {
	width: 100%;
	padding: 0.75rem 1rem 0.75rem 2.5rem;
	border: 1px solid var(--border);
	border-radius: 8px;
	font-size: 0.875rem;
	transition: all 0.2s ease;
	color: var(--text-primary);
}

.search-input:focus {
	outline: none;
	border-color: var(--primary);
	box-shadow: 0 0 0 3px var(--primary-light);
}

.blacklist-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 1.5rem;
	padding: 0.5rem;
}

.blacklist-card {
	background: var(--surface);
	border-radius: 12px;
	border: 1px solid var(--border);
	padding: 1.5rem;
	transition: all 0.2s ease;
}

.blacklist-card:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.card-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.email-container {
	word-break: break-all;
}

.email {
	color: var(--text-primary);
	font-weight: 500;
	font-size: 0.875rem;
	margin: 0;
}

.banned-date {
	color: var(--text-tertiary);
	font-size: 0.75rem;
	margin-top: 0.25rem;
}

.reason {
	color: var(--text-secondary);
	font-size: 0.875rem;
	margin: 1rem 0;
	line-height: 1.5;
}

.remove-button {
	padding: 0.5rem;
	border: none;
	background: transparent;
	color: var(--danger);
	cursor: pointer;
	border-radius: 6px;
	transition: all 0.2s ease;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.875rem;
}

.remove-button:hover {
	background: var(--danger-light);
}

.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

.loading-text {
	color: var(--text-secondary);
	font-size: 0.875rem;
}

.error-container {
	text-align: center;
	padding: 2rem;
	color: var(--danger);
	background: var(--danger-light);
	border-radius: 12px;
	margin: 2rem;
}

@media (max-width: 768px) {
	.blacklist-header {
		flex-direction: column;
		align-items: stretch;
		gap: 1rem;
	}

	.search-container {
		width: 100%;
	}

	.blacklist-grid {
		grid-template-columns: 1fr;
	}
}
