/* Reset and base styles */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Wrapper for entire admin panel */
.admin-wrapper {
	display: flex;
	width: 100%;
	min-height: 100vh;
	background: #f3f4f6;
	overflow-x: hidden;
}

/* Main dashboard layout */
.admin-dashboard {
	display: flex;
	width: 100%;
	min-height: 100vh;
	position: relative;
	background: #f8fafc;
}

/* Modern Minimalistic Sidebar */
.dashboard-nav {
	width: 220px;
	min-width: 220px;
	height: 100vh;
	background: #ffffff;
	left: 0;
	top: 0;
	overflow-y: auto;
	z-index: 40;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	transition: all 0.3s ease;
	display: flex;
	flex-direction: column;
	align-items: center !important;
	justify-content: flex-start !important;
}

.nav-header {
	padding: 2rem 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border-bottom: 1px solid #f1f5f9;
	margin-bottom: 0.5rem;
}

.nav-brand {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.nav-title {
	font-size: 1.25rem;
	font-weight: 600;
	color: #1e293b;
	letter-spacing: -0.5px;
}

.nav-subtitle {
	font-size: 0.75rem;
	color: #94a3b8;
	font-weight: 500;
}

.nav-menu {
	padding: 0.75rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.nav-item {
	display: flex;
	align-items: center;
	padding: 0.75rem 1rem;
	border-radius: 6px;
	color: #64748b;
	cursor: pointer;
	transition: all 0.2s ease;
	user-select: none;
	font-size: 0.875rem;
	font-weight: 500;
}

.nav-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	height: 18px;
	margin-right: 12px;
	opacity: 0.75;
	transition: all 0.2s ease;
}

.nav-item:hover {
	background: #f8fafc;
	color: #334155;
}

.nav-item:hover .nav-icon {
	opacity: 1;
}

.nav-item.active {
	background: #f8fafc;
	color: #f49e53;
	font-weight: 600;
}

.nav-item.active .nav-icon {
	opacity: 1;
	color: #f49e53;
}

/* Main content area adjustment */
.dashboard-main {
	flex: 1;
	margin-left: 220px;
	padding: 2.5rem;
	background: #f8fafc;
	min-height: 100vh;
	width: calc(100% - 220px);
	transition: all 0.3s ease;
}

.dashboard-content {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
}

/* Modern Stats Grid */
.admin-panel__stats {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	gap: 1.75rem;
	width: 100%;
	margin-bottom: 2.5rem;
}

/* Enhanced Stat Card */
.stat-card {
	background: white;
	padding: 1.75rem;
	border-radius: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.03);
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	width: 100%;
	transition: all 0.3s ease;
	position: relative;
	overflow: hidden;
}

.stat-card:hover {
	transform: translateY(-2px);
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.04);
}

.stat-card::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background: linear-gradient(to right, #f49e53, #f4c053);
	opacity: 0;
	transition: opacity 0.3s ease;
}

.stat-card:hover::before {
	opacity: 1;
}

.stat-card__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.stat-card__title {
	font-size: 0.875rem;
	color: #64748b;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.stat-card__icon {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f8fafc;
	border-radius: 8px;
	color: #f49e53;
}

.stat-card__value {
	font-size: 2rem;
	font-weight: 600;
	color: #1e293b;
	line-height: 1.2;
}

.stat-card__trend {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.875rem;
}

.trend-positive {
	color: #10b981;
}

.trend-negative {
	color: #ef4444;
}

/* Enhanced Auth Form */
.admin-auth-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
	padding: 1.5rem;
}

.admin-auth-form {
	background: white;
	padding: 2.5rem;
	border-radius: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02), 0 1px 3px rgba(0, 0, 0, 0.03);
	width: 100%;
	max-width: 420px;
	text-align: center;
}

.admin-auth-form h2 {
	margin-bottom: 2rem;
	color: #1e293b;
	font-size: 1.75rem;
	font-weight: 600;
}

.admin-auth-form input {
	width: 100%;
	padding: 0.875rem 1rem;
	margin-bottom: 1.25rem;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	font-size: 1rem;
	transition: all 0.2s ease;
	background: #f8fafc;
}

.admin-auth-form input:focus {
	outline: none;
	border-color: #f49e53;
	box-shadow: 0 0 0 3px rgba(244, 158, 83, 0.1);
}

.admin-auth-form button {
	width: 100%;
	padding: 0.875rem;
	background: #f49e53;
	color: white;
	border: none;
	border-radius: 8px;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.2s ease;
}

.admin-auth-form button:hover {
	background: #e88d42;
	transform: translateY(-1px);
}

.admin-auth-form button:active {
	transform: translateY(0);
}

.error-message {
	color: #ef4444;
	margin-bottom: 1.25rem;
	font-size: 0.875rem;
	padding: 0.75rem;
	background: #fef2f2;
	border-radius: 6px;
	border: 1px solid #fee2e2;
}

/* Loading States */
.loading-skeleton {
	background: linear-gradient(90deg, #f1f5f9 0%, #e2e8f0 50%, #f1f5f9 100%);
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
	border-radius: 8px;
}

@keyframes loading {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}

/* Empty States */
.empty-state {
	text-align: center;
	padding: 3rem;
	background: white;
	border-radius: 16px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02);
}

.empty-state__icon {
	font-size: 3rem;
	color: #cbd5e1;
	margin-bottom: 1.5rem;
}

.empty-state__title {
	font-size: 1.25rem;
	color: #1e293b;
	margin-bottom: 0.75rem;
}

.empty-state__description {
	color: #64748b;
	max-width: 400px;
	margin: 0 auto;
}

/* Mobile Responsiveness Enhancement */
@media (max-width: 1024px) {
	.dashboard-nav {
		transform: translateX(-100%);
		transition: transform 0.3s ease;
	}

	.dashboard-nav.active {
		transform: translateX(0);
	}

	.dashboard-main {
		margin-left: 0;
		width: 100%;
		padding: 1.5rem;
	}

	.nav-overlay {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(2px);
		z-index: 30;
		transition: opacity 0.3s ease;
	}

	.nav-overlay.active {
		display: block;
		animation: fadeIn 0.3s ease;
	}
}

@media (max-width: 768px) {
	.dashboard-main {
		padding: 1rem;
	}

	.admin-panel__stats {
		grid-template-columns: 1fr;
	}

	.stat-card {
		padding: 1.5rem;
	}

	.admin-auth-form {
		padding: 2rem;
	}
}

/* Animations */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.nav-menu {
	animation: fadeIn 0.1s ease;
}
