@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

#root {
	overflow-x: hidden;
}
body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Plus Jakarta Sans', 'Poppins', 'Inter', 'Open Sans', sans-serif;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}
:root {
	--primary-gradient: linear-gradient(to right, #ffb06c, #f49e53);
	--foreground: #fae6c0;
	--safe-area-inset-top: env(safe-area-inset-top, 0px);
	--safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
	--safe-area-inset-left: env(safe-area-inset-left, 0px);
	--safe-area-inset-right: env(safe-area-inset-right, 0px);
}
.div-main {
	height: 100dvh !important;
	max-height: 100dvh !important;
}

/* PWA specific styles */
@media (display-mode: standalone), (display-mode: fullscreen), (display-mode: minimal-ui) {
	.div-main {
		height: calc(100dvh - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)) !important;
		max-height: calc(100dvh - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)) !important;
		padding-top: var(--safe-area-inset-top);
		padding-bottom: var(--safe-area-inset-bottom);
		padding-left: var(--safe-area-inset-left);
		padding-right: var(--safe-area-inset-right);
	}
}

/* iOS PWA specific styles */
@supports (-webkit-touch-callout: none) {
	.div-main {
		/* iOS specific padding for PWA mode */
		padding-top: max(var(--safe-area-inset-top), 10px);
		padding-bottom: max(var(--safe-area-inset-bottom), 10px);
	}
}

/* PWA mode specific styles */
body.pwa-mode {
	/* Prevent overscroll/bounce effects */
	overscroll-behavior: none;
	-webkit-overflow-scrolling: touch;
}

/* iOS PWA specific styles */
body.pwa-ios {
	/* Fix for iOS height calculation */
	height: calc(100 * var(--vh, 1vh));
}

body.pwa-ios .div-main {
	/* Use the custom vh variable for iOS */
	height: calc(100 * var(--vh, 1vh)) !important;
	max-height: calc(100 * var(--vh, 1vh)) !important;
	/* Apply safe area insets */
	padding-top: var(--safe-area-inset-top);
	padding-bottom: var(--safe-area-inset-bottom);
	padding-left: var(--safe-area-inset-left);
	padding-right: var(--safe-area-inset-right);
}

/* Android PWA specific styles */
body.pwa-android .div-main {
	/* Apply safe area insets */
	padding-top: var(--safe-area-inset-top);
	padding-bottom: var(--safe-area-inset-bottom);
	padding-left: var(--safe-area-inset-left);
	padding-right: var(--safe-area-inset-right);
}

/* Ensure content is properly contained in PWA mode */
@media (display-mode: standalone), (display-mode: fullscreen), (display-mode: minimal-ui) {
	.chat-layout__container,
	.friends-list__container,
	.chat-window__container,
	.community-page,
	.community-chat__container {
		/* Ensure content doesn't overflow */
		max-height: 100%;
		overflow: hidden;
	}

	.chat-window__messages-container {
		/* Adjust based on your header and input heights */
		max-height: calc(100% - 120px);
		overflow-y: auto;
	}
}

.headline {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 32px;
}
.headline h2 {
	text-align: center;
	font-weight: bold;
	line-height: 1.1;
	color: #140c01;
	font-size: 64px;
	margin: 0px;
}
.headline p {
	text-align: center;
	font-size: 16px;
	font-family: 'Open sans';
	color: #140c01;
	margin: 0px;
	max-width: 364px;
}
@media (max-width: 768px) {
	.headline h2 {
		font-size: 48px;
	}
	.headline p {
		font-size: 16px;
	}
}
@media (max-width: 480px) {
	.headline h2 {
		font-size: 32px;
	}
	.headline p {
		font-size: 14px;
	}
}
.px-section-break {
	height: 128px;
	width: 100%;
	background: var(--foreground);
}
.px-section-break-secondary {
	height: 128px;
	width: 100%;
	background: #fffdf8;
	position: relative;
}

.px-section-break-secondary::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('./assets/Background.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.4;
}

.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #ffffff;
}

.spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #d9d9d9;
	border-top: 5px solid #f49e53;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
