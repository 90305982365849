:root {
	/* Core colors */
	--primary: #3b82f6;
	--primary-light: #dbeafe;
	--surface: #ffffff;
	--background: #f8fafc;

	/* Text colors */
	--text-primary: #1e293b;
	--text-secondary: #64748b;
	--text-tertiary: #94a3b8;

	/* Status colors */
	--danger: #ef4444;
	--danger-light: #fee2e2;
	--warning: #f59e0b;
	--warning-light: #fef3c7;
	--success: #10b981;
	--success-light: #d1fae5;

	/* Borders & Shadows */
	--border: #e2e8f0;
	--border-light: #f1f5f9;
	--shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
	--shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.08);
	--shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.08);
}

.reports-section {
	width: 100%;
	padding: 2.5rem;
	background: var(--background);
	min-height: 100vh;
}

.reports-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
}

.reports-title {
	font-size: 1.875rem;
	font-weight: 600;
	color: var(--text-primary);
	margin-bottom: 0.75rem;
}

.reports-subtitle {
	color: var(--text-secondary);
	font-size: 1rem;
	max-width: 600px;
	line-height: 1.5;
}

.reports-controls {
	display: flex;
	gap: 1rem;
	margin: 2rem 0;
	padding: 1.25rem;
	background: var(--surface);
	border-radius: 12px;
	box-shadow: var(--shadow-sm);
}

.search-input {
	flex: 1;
	max-width: 400px;
	position: relative;
}

.search-input input {
	width: 100%;
	padding: 0.75rem 1rem 0.75rem 2.75rem;
	border: 1px solid var(--border);
	border-radius: 8px;
	font-size: 0.875rem;
	transition: all 0.2s ease;
	color: var(--text-primary);
}

.search-input input:focus {
	border-color: var(--primary);
	box-shadow: 0 0 0 3px var(--primary-light);
	outline: none;
}

.search-icon {
	position: absolute;
	left: 1rem;
	top: 50%;
	transform: translateY(-50%);
	color: var(--text-tertiary);
}

.reports-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
	gap: 1.5rem;
	width: 100%;
}

.report-card {
	background: var(--surface);
	border-radius: 16px;
	overflow: hidden;
	transition: all 0.2s ease;
	border: 1px solid var(--border);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.report-card:hover {
	transform: translateY(-2px);
	box-shadow: var(--shadow-lg);
}

.report-header {
	padding: 1.5rem;
	border-bottom: 1px solid var(--border-light);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.report-severity {
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	font-weight: 500;
	font-size: 0.875rem;
}

.report-severity.high {
	background: var(--danger-light);
	color: var(--danger);
}

.report-timestamp {
	color: var(--text-tertiary);
	font-size: 0.875rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.report-content {
	padding: 1.5rem;
}

.involved-users {
	display: flex;
	gap: 1.5rem;
	margin-bottom: 1.5rem;
}

.user-profile {
	flex: 1;
	background: var(--background);
	padding: 1rem;
	border-radius: 12px;
	position: relative;
}

.user-profile::before {
	content: 'Reported By';
	position: absolute;
	top: -0.75rem;
	left: 1rem;
	background: var(--primary-light);
	color: var(--primary);
	padding: 0.25rem 0.75rem;
	border-radius: 12px;
	font-size: 0.75rem;
	font-weight: 500;
}

.user-profile.reported::before {
	content: 'Reported User';
	background: var(--danger-light);
	color: var(--danger);
}

.user-info {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	border: 2px solid var(--surface);
}

.user-details h4 {
	margin: 0;
	color: var(--text-primary);
	font-size: 0.875rem;
	font-weight: 600;
}

.user-details p {
	margin: 0;
	color: var(--text-secondary);
	font-size: 0.75rem;
}

.report-reason {
	margin-top: 1.5rem;
	padding: 1.25rem;
	background: var(--background);
	border-radius: 12px;
}

.reason-label {
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: var(--text-tertiary);
	margin-bottom: 0.5rem;
}

.reason-text {
	color: var(--text-primary);
	font-size: 0.875rem;
	line-height: 1.6;
	margin: 0;
}

.report-actions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	padding: 1.5rem;
	background: var(--background);
	border-top: 1px solid var(--border);
}

.action-button {
	padding: 0.75rem 1.5rem;
	border-radius: 8px;
	font-size: 0.875rem;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	transition: all 0.2s ease;
	cursor: pointer;
	border: none;
}

.dismiss-button {
	background: var(--surface);
	color: var(--text-secondary);
	border: 1px solid var(--border);
}

.dismiss-button:hover {
	background: var(--background);
	color: var(--text-primary);
	border-color: var(--text-tertiary);
}

.delete-button {
	background: var(--danger-light);
	color: var(--danger);
}

.delete-button:hover {
	background: var(--danger);
	color: var(--surface);
}

.report-chat-history {
	margin-top: 1.5rem;
	padding: 1.25rem;
	background: var(--background);
	border-radius: 12px;
}

.chat-history-label {
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: var(--text-tertiary);
	margin-bottom: 1rem;
}

.chat-messages {
	max-height: 300px;
	overflow-y: auto;
	padding-right: 0.5rem;
}

.chat-message {
	margin-bottom: 1rem;
	padding: 0.75rem;
	border-radius: 8px;
	background: var(--surface);
}

.reported-user-message {
	border-left: 3px solid var(--danger);
}

.reporter-message {
	border-left: 3px solid var(--primary);
}

.message-header {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 0.5rem;
}

.message-avatar {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	object-fit: cover;
}

.message-sender {
	font-size: 0.875rem;
	font-weight: 500;
	color: var(--text-primary);
}

.message-time {
	font-size: 0.75rem;
	color: var(--text-tertiary);
	margin-left: auto;
}

.message-content {
	font-size: 0.875rem;
	color: var(--text-primary);
	line-height: 1.5;
}

.deleted-message {
	font-style: italic;
	color: var(--text-tertiary);
}

/* Add responsive styles */
@media (max-width: 768px) {
	.reports-section {
		padding: 1.5rem;
	}

	.reports-grid {
		grid-template-columns: 1fr;
	}

	.reports-controls {
		flex-direction: column;
	}

	.search-input {
		max-width: 100%;
	}

	.involved-users {
		flex-direction: column;
	}

	.reported-user-message,
	.reporter-message {
		margin-left: 0;
		margin-right: 0;
	}
}

/* Add these to your existing styles */

.loading {
	text-align: center;
	padding: 2rem;
	color: var(--text-secondary);
}

.error {
	text-align: center;
	padding: 2rem;
	color: var(--danger);
}

.report-actions {
	display: flex;
	gap: 1rem;
	margin-top: 1.5rem;
}

.action-button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.75rem 1rem;
	border-radius: 8px;
	font-size: 0.875rem;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.2s ease;
}

.action-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.dismiss-button {
	background: var(--surface);
	color: var(--text-primary);
	border: 1px solid var(--border);
}

.dismiss-button:hover:not(:disabled) {
	background: var(--surface-hover);
}

.delete-button {
	background: var(--danger);
	color: white;
	border: none;
}

.delete-button:hover:not(:disabled) {
	background: var(--danger-hover);
}

.reports-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
}

.search-container {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.search-icon {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	color: var(--text-tertiary);
	width: 16px;
	height: 16px;
}

.search-input {
	width: 100%;
	padding: 0.75rem 1rem 0.75rem 2.5rem;
	border: 1px solid var(--border);
	border-radius: 8px;
	font-size: 0.875rem;
	background: var(--surface);
	color: var(--text-primary);
	transition: all 0.2s ease;
}

.search-input:focus {
	outline: none;
	border-color: var(--primary);
	box-shadow: 0 0 0 2px var(--primary-light);
}

.search-input::placeholder {
	color: var(--text-tertiary);
}

@media (max-width: 768px) {
	.reports-header {
		flex-direction: column;
		gap: 1rem;
	}

	.search-container {
		width: 100%;
	}
}

.reports-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 1rem;
	box-sizing: border-box;
}
